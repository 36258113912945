import { Box } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function LoadingChart({title}: any) {

    let chartTypes = ["pie","bar"];
    const chartType = chartTypes[Math.floor(Math.random() * chartTypes.length)];
    return (
        <Box
            sx={{
                opacity: 0.3,
                filter: 'grayscale(100%)'
            }}
        >
            <HighchartsReact
                highcharts={Highcharts}
                options={{
                    chart: {
                        type: chartType,
                        backgroundColor: 'transparent'
                    },
                    title: {
                        text: title
                    },
                    series: [{
                        name: 'Sample',
                        data: [
                            ['Not Started Yet', 45],
                            ['In Progress', 25],
                            ['Completed', 30]
                        ]
                    }],
                    plotOptions: {
                        pie: {
                            colors: ['#d3d3d3', '#a9a9a9', '#808080'],
                            dataLabels: {
                                enabled: true,
                                format: '{point.name}: {point.percentage:.1f}%'
                            }
                        },
                        bar: {
                            colors: ['#d3d3d3', '#a9a9a9', '#808080'],
                            dataLabels: {
                                enabled: true,

                            }
                        }
                    },
                    credits: {
                        enabled: false
                    }
                }}
            />
        </Box>
    )
}