import { Paper, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function RatingChart({ report, rating, chartComponentRef }: any) {

    let options: Highcharts.Options = getRatingChartOptions(rating);
    let rate = 100;
    let unres = 0;
    if (rating.ratingCount.hasOwnProperty("")) {
      unres = rating.ratingCount[""];
      let res = report.participants;

      rate = Math.round(100 - (unres / res) * 100);
    }

    return (
        <>
          <Paper
            className="reportTile"
            key={rating.title}
          >
            <Typography sx={{ opacity: "0.8", textTransform: "none" }}>
              {rate}% response rate{" "}
              {unres > 0 && (
                <span>({unres} participants didn&apos;t respond)</span>
              )}
            </Typography>
            <br />
            <br />
            <HighchartsReact
              highcharts={Highcharts}
              options={options}
              ref={chartComponentRef}
            />
          </Paper>
        </>
      );
}

function getRatingChartOptions(rating: any): Highcharts.Options {
    return {
      title: {
        text: rating.title,
      },
      colors: ['#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '#5090B7', '#39C1C1', '#3AD7B0', '#8266E1', '#003f5c', '#'],
      yAxis: {
        reversedStacks: false,
        title: {
          text: null
        },
        labels: { enabled: false }
      },
      xAxis: {
        categories: [""],
        reversedStacks: false,
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: GetOrderedKeys(rating.ratingCount).map((key: string) => {
        return {
          name: key,
          type: "column",
          data: [rating.ratingCount[key]],
        };
      }),
    };
  }

  function GetOrderedKeys(multi: Record<string, number>) {
    return Object.keys(multi)
      .filter((z) => z !== "")
      .sort(function (a, b) {
        return parseInt(a) - parseInt(b);
      });
  }