import { Container, Box, Grid, Typography, IconButton, CircularProgress, Button, Paper } from "@mui/material";
import NpsInsightsChart from "components/insights/charts/npsInsightsChart";
import OpinionInsightsChart from "components/insights/charts/opinionInsightsChart";
import RatingInsightsChart from "components/insights/charts/ratingInsightsChart";
import Highcharts from "highcharts";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useState } from "react";
import CreateIcon from '@mui/icons-material/Create';
import { InsightsChartsProps } from "components/insights/insightTypes";
import AddInsightChart from "../addChart/addChart";
import OpinionSummaryInsightsChart from "components/insights/charts/opinionSummaryInsightsChart";


export default function ViewInsightsCharts({ insightsCharts, programId, fetchData }: InsightsChartsProps) {

    const [modalOpen, setModalOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    return (
        <Container maxWidth={false} disableGutters>
            <Box maxWidth="xl" mx="auto">
                <Grid container>
                    <Grid item xs={2}>
                        <Typography variant="h6" sx={{ my: 4, ml: 2 }}>View Insights &nbsp;
                            {!editMode &&
                                <IconButton onClick={() => setEditMode(true)} sx={{ opacity: "0.6", textTransform: 'none', mt: 0 }}>
                                    <CreateIcon fontSize="small" />
                                </IconButton >
                            }{editMode &&
                                <IconButton onClick={() => setEditMode(false)} sx={{ opacity: "0.6", textTransform: 'none', mt: 0 }}>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton >
                            } </Typography></Grid>
                </Grid>
                <Grid container spacing={2}>
                    {insightsCharts.map((insight) => (
                        <Grid item xs={6} key={insight.dataPointId}>
                        <Paper
                            className="engagement-box"
                            key={insight.title}
                        >
                        <Box key={insight.dataPointId} className="insight-box">
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={2}>
                                <Typography variant="subtitle1">{insight.title}
                                    <div style={{ fontSize: 13 }}>{insight.description}</div>
                                </Typography>
                                <Typography variant="subtitle2" hidden={editMode}>{insight.type[0].toUpperCase() + insight.type.slice(1).replace(/(?!^)([A-Z]|\d+)/g, " $1")}</Typography>
                            </Box>
                            <Box className="insight-chart-container">
                                {insight.type.toLowerCase() == "rating" &&
                                    <RatingInsightsChart
                                        highcharts={Highcharts}
                                        insight={insight} />
                                }
                                {insight.type.toLowerCase() == "opinion" &&
                                    <OpinionInsightsChart
                                        highcharts={Highcharts}
                                        insight={insight} />
                                }
                                {insight.type.toLowerCase() == "nps" &&
                                    <NpsInsightsChart
                                        highcharts={Highcharts}
                                        insight={insight} />
                                }
                                 {insight.type.toLowerCase() == "opinionsummary" &&
                                    <OpinionSummaryInsightsChart
                                        highcharts={Highcharts}
                                        insight={insight} />
                                }
                            </Box>
                        </Box>
                        </Paper>
                        </Grid>
                    ))}
                    <Grid item xs={6}>
                        <Paper className="engagement-box add-engagement-box" hidden={!editMode}>
                            <Button variant="outlined" onClick={() => setModalOpen(true)}>
                                Add Insight
                            </Button>
                        </Paper>
                    </Grid>

                    <AddInsightChart
                        fetchData={fetchData}
                        setModalOpen={setModalOpen}
                        programId={programId}
                        open={modalOpen}
                    />
                </Grid>
            </Box>
        </Container>
    );
}