import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useSearchParams  } from "react-router-dom";


const LoginPage = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!isAuthenticated && !searchParams.get("code")) {
      loginWithRedirect();
    }else {
        navigate("/programs/");
    }
  }, []);

  // Render your login page components here
  return (
    <div>
      {/* Your login page UI */}
    </div>
  );
};

export default LoginPage;
