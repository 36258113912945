import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { InsightChart } from "../insightTypes";

export default function RatingInsightsChart({ insight }: any) {

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={getChartOptions(insight)} />

    )
}


const getChartOptions = (insight: InsightChart): Highcharts.Options => {
    const { data, title, description } = insight;

    return {
        colors: ['#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '#5090B7', '#39C1C1', '#3AD7B0', '#8266E1', '#003f5c', '#'],
        chart: {
            type: 'column',
        },
        title: {
            text: "",
            align: 'left',
        },
        subtitle: {
            text: description,
            align: 'left',
        },
        xAxis: {
            categories: data.categories,
            crosshair: true
        },
        credits: {
            enabled: false
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count'
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: Object.entries(data.series).map(([key, values]) => ({
            name: key,
            data: values,
            type: 'column' as const
        }))
    };
};
