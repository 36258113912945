export interface ProgramReportQuestions {
  programId: string;
  reports: ReportQuestion[]
}

export interface ReportQuestion {
  reportId: string;
  title: string;
  reportDate: Date;
  questionIds: ReportQuestionId[]
}

export interface ReportQuestionId
{ 
  questionId: string;
  title: string;
  type: InsightsChartType;
}

export interface QuestionMapping {
  reportId: string;
  questionId: string;
}


export interface InsightChart {
  dataPointId: string;
  title: string;
  description: string;
  order: number;
  type: string;
  data: {
    categories: string[];
    series: {
      [key: string]: number[];
    };
  };
  questionMappings: QuestionMapping[];
}

export interface InsightsResponse {
  programId: string;
  clientId: string;
  insightsId: string;
  insightsCharts: InsightChart[];
  status: string;
}

export interface InsightsChartsProps {
  insightsCharts: InsightChart[];
  fetchData: () => void;
  programId: string;
}

export enum InsightsChartType {
  Unmapped = "Unmapped",
  Opinion = "Opinion",
  OpinionSummary = "OpinionSummary",
  Open = "Open",
  Multi = "Multi",
  Nps = "Nps",
  Ranking = "Ranking",
  Rating = "Rating"
}