import { Paper, Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function OpinionChart({ report, multi, chartComponentRef }: any) {

  let rate = 100;
  let unres = 0;
  if (multi.multiCount.hasOwnProperty("")) {
    unres = multi.multiCount[""];
    let res = report.participants;

    rate = Math.round(100 - (unres / res) * 100);
  }

  let options: Highcharts.Options = getOpinionChartOptions(multi, report.participants - unres);

  return (
    <>
      <Paper className="reportTile" key={multi.title}>
        <Typography sx={{ opacity: "0.8", textTransform: "none" }}>
          {rate}% response rate{" "}
          {unres > 0 && (
            <span>({unres} participants didn&apos;t respond)</span>
          )}
        </Typography>
        <br />
        <br />
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartComponentRef}
        />
      </Paper>
    </>
  );
}

function getOpinionChartOptions(multi: any, participants: any): Highcharts.Options {
  return {
    title: {
      text: multi.title,
    },
    colors: ['#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600', '#5090B7', '#39C1C1', '#3AD7B0', '#8266E1', '#003f5c', '#'],
    yAxis: {
      reversedStacks: false,
      title: {
        text: null
      },
      labels: { enabled: false }
    },
    xAxis: {
      categories: [""],
      reversedStacks: false,

    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
          format: '{point.y:,.0f} %'
        },

      },
    },
    series: GetOrderedOpinionKeys(multi.multiCount).map((key: string) => {
      return {
        name: key,
        type: "bar",
        data: [Math.round(multi.multiCount[key] / participants * 100 * 100) / 100],
      };
    }),
  };
}

function GetOrderedOpinionKeys(multi: Record<string, number>) {
  const order = [
    "not confident at all",
    "not confident",
    "a little confident",
    "somewhat confident",
    "confident",
    "quite confident",
    "very confident",
    "strongly disagree",
    "disagree",
    "neutral",
    "neither agree nor disagree",
    "agree",
    "strongly agree",
    "never",
    "rarely",
    "sometimes",
    "often",
    "always",
    "very dissatisfied",
    "dissatisfied",
    "neutral",
    "neither satisfied nor dissatisfied",
    "satisfied",
    "very satisfied",
  ];
  return Object.keys(multi).filter((z) => z !== "").sort(function (a, b) {
    return order.indexOf(a.toLowerCase()) - order.indexOf(b.toLowerCase());
  });
}